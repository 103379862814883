@use 'variables' as *;
@use 'mixins' as *;

.hero {
  display: flex;
  align-items: center;
  width: 100%;
  height: 83.4vh;
  max-height: 1080px;
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.23), rgba(0, 0, 0, 0.23)),
    url('/images/home-page/home-page-background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  > h2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > span {
      display: block;
      font-size: 63px;
      font-style: normal;
      font-weight: bold;
      line-height: 1.4;
      color: #fff;

      &:nth-child(2) {
        background-image: linear-gradient(to right, #df6054, #df6054);
        background-repeat: no-repeat;
        background-position: 100% calc(100% - -24px);
        background-size: 100% 60%;
      }
    }
  }

  @include mq('lg') {
    height: 73.5vh;
  }

  @include mq('md') {
    height: 66vh;
  }

  @include mq('sm') {
    height: 40vh;

    > h2 > span {
      font-size: 34px;

      &:nth-child(2) {
        background-position: 100% calc(100% - -14px);
      }
    }
  }
}
.MarketplaceButton {
  font-size: 30px;

  color: #fff;
  margin-top: 1%;
  font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  border-width: 3px;
  @include mq('lg') {
    font-size: 28px;
  }

  @include mq('md') {
    font-size: 28px;
  }

  @include mq('sm') {
    height: 18px;
  }
}
.MarketplaceButton:hover {
  cursor: pointer;
  color: #ddd;
}
