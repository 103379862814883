@use 'mixins' as *;
.LimitedEditionGrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #5CA984;;
    color: #fff;
    padding-left: 30px;
    padding-bottom: 20px;
    padding-top: 20px;
    font-family: 'Noto Sans JP';
    @include mq('md') {
      display: inline-block;
    }
  }
  .LimitedEditionHeader{
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0.15px;
    color: #FFFFFF;
  }

  .LimitedEditionText{
    padding-top: 20px;
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.15px;
    width: 115%;
    @include mq('lg') {
      width: auto;
    }
  }
  .LearnMore{
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 1px 12px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
    width: auto;
    padding: 10px 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-top: 40px;
  }
  .LearnImage{
    float: right;

  }
  .LearnImageDiv{
    @include mq('sm') {
      margin: auto;
      width: 60%;
    }
  }
  .LearnMoreButtonDiv{
    @include mq('sm') {
      text-align: center;
    }
  }