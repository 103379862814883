.creationImage {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
  object-fit: cover;
}

.itemBody {
  padding: 20px;
}

.itemImageContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0px 5px 13px -2px rgba(0, 0, 0, 0.6);
  border-radius: 4px;
}

.itemImageArea {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.itemImageContent {
  position: absolute;
  inset: 0;
}

.itemDescription {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 20px;
  color: #000;
  gap: 4px;
}

.creationName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  overflow: hidden;
  letter-spacing: 0.15px;
  font-size: 18px;
  text-align: center;
  max-width: 100%;
}

.creationNameInverted {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  overflow: hidden;
  letter-spacing: 0.15px;
  font-size: 18px;
  text-align: center;
  max-width: 100%;
  color: #fff;
}

.itemCreator {
  display: flex;
  flex: 1;
  gap: 4px;
  align-items: center;
  font-weight: normal;

  > img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.creatorName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.creatorNameInverted {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
}
