@use 'mixins' as *;

.creatorApplicationContainer {
  display: flex;
  align-items: center;
  gap: 30px;
}
.creatorSection{
  width: inherit;
  height: inherit;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  > h2 {
    width: 30%;

    text-align: center;
    margin-left: auto;
    margin-right: auto;
    line-break: auto;
    font-size: 52px;
    font-style: normal;
    font-weight: bold;
    color: #fff;
    display: block;
    @include mq('md') {
      width: 55%
    }
  }

  > p {
    display: block;
    width: 40%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    color: rgba(255, 255, 255);
    @include mq('md') {
      width: 65%
    }
  }
}
.creatorBackground{
  width: 100%;
  background-image: url(/images/landing-page/anifty_creators.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 500px;
  @include mq('md') {
    height: 800px;
  }
  min-height: max-content;

}
.registerButton{
  width: auto;
  min-width: 20%;
  padding: 15px 35px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  margin-top: 35px;
  margin-bottom: 35px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 1px 12px rgba(0, 0, 0, 0.12);
}
