@use 'mixins' as *;
.IPFSGrid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #15154F;
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  font-family: 'Noto Sans JP';
  @include mq('md') {
    display: inline-block;
  }
}
.ImmunityHeader{
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 120%;
  letter-spacing: 0.15px;
  color: #FFFFFF;
}

.ImmunityText{

  padding-top: 20px;
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
}
.LearnMore{
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 1px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  width: auto;
  padding: 10px 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.IPFSImage{
  width: 310px;
  height: 350px;
  float: right;
}
.IPFSImageDiv{
  @include mq('sm') {
    margin: auto;
    width: 80%;
  }
}
.LearnMoreButtonDiv{
  @include mq('sm') {
    text-align: center;
  }
}