@use 'mixins' as *;

.featuredSection {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: space-between;

  @include mq('md') {
    flex-direction: column-reverse;
  }

  img {
    pointer-events: none;
    user-select: none;
  }
}

.creationInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
  justify-content: flex-start;

  @include mq('sm') {
    width: 100%;
  }

  > h2 {
    display: flex;
    align-items: center;
    font-size: 46px;
    font-weight: bold;
    line-height: 1;
    color: #000000;
    letter-spacing: 0.15px;

    @include mq('sm') {
      font-size: 2em;
    }
  }
}

.creatorInfo {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 30px;
  font-weight: 500;
  line-height: 28px;
  color: #707a83;
  letter-spacing: 0.15px;

  > a {
    display: inline-flex;
    gap: 5px;
    align-items: center;
    padding: 9px 15px;
    color: inherit;
    text-decoration: none;
    background: #ffffff;
    border-radius: 60px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.09);
  }

  @include mq('sm') {
    font-size: 1em;
  }
}

.navigationButtonContainer {
  display: flex;
  gap: 30px;
  align-items: center;

  button,
  a {
    padding: 20px 50px;
    font-size: 12px;
  }

  @include mq('sm') {
    gap: 20px;

    button,
    a {
      flex: 1;
      padding: 20px 10px;
    }
  }
}

.creationImageContainer {
  display: flex;
  width: 100%;
  max-width: 560px !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.creationImage {
  min-width: 501px;
  height: auto;
  min-height: 354px;
  @include mq('xl') {
    min-width: 100%;
    min-height: 100%;
  }
}
