.framedIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  background: #fff;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
  border-radius: 50%;
  position: relative;

  > img {
    pointer-events: none;
    user-select: none;
    border-radius: 50%;
  }

  > svg {
    position: absolute;
    width: 42%;
    height: auto;
    right: 0;
    bottom: 0;
  }
}
