@use 'mixins' as *;

.creationSectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #b7b7b7;

  > h2 {
    font-size: 27px;
    font-weight: 500;
    color: #000000;
    letter-spacing: 0.15px;
  }

  > a {
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
    letter-spacing: 0.15px;
    transition: all 0.1s;

    &:hover {
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

.creationList {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 33px;
  padding: 0;
  margin: 20px 0 0;
  list-style: none;

  @include mq('lg') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include mq('sm') {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  > a {
    position: relative;
    width: 100%;
    transition: all 0.4s;

    &:hover {
      transform: translateY(-3px);
    }
  }
}
