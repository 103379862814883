@use 'mixins' as *;

.landingPageContent {
  display: flex;
  flex-direction: column;

  > section {
    padding: 80px 0;
    background: #fff;
  }

  > section:nth-child(even) {
    background: linear-gradient(0deg, #f9fafb, #f9fafb);
  }
}

.IPFSLimitedGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @include mq('lg') {
    display: inline-block;
  }
}
