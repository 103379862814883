@use 'variables' as *;
@use 'mixins' as *;

.customButton {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 8px 16px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  background: $color-primary;
  border: 0;
  border-radius: 8px;

  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.02),
    0 1px 12px rgba(0, 0, 0, 0.12);

  transition: all 0.2s;

  &:hover,
  &:focus {
    background: lighten($color-primary, 4%);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.1),
      0 1px 12px rgba(0, 0, 0, 0.12);
  }

  &:active {
    background: lighten($color-primary, 8%);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.02),
      0 1px 12px rgba(0, 0, 0, 0.12);
  }
}

.customButton:disabled {
  color: #8d8d8d;
  cursor: default;
  background-color: #e7e7e7;
  border-color: #e7e7e7;
  box-shadow: none;

  &:hover,
  &:focus {
    background-color: #e7e7e7;
  }

  &:active {
    background-color: #e7e7e7;
  }
}

/**
 * loading (show circular progress)
 */
.loading {
  $loader-size: 30px;

  &:after {
    position: absolute;
    width: $loader-size;
    height: $loader-size;
    margin: auto;
    font-size: 10px;
    text-indent: -9999em;
    content: '';
    border-top: 4px solid rgba(255, 255, 255, 0.2);
    border-right: 4px solid rgba(255, 255, 255, 0.2);
    border-bottom: 4px solid rgba(255, 255, 255, 0.2);
    border-left: 4px solid #fff;
    border-radius: 50%;
    transform: translateZ(0);
    animation: spinner 1.1s infinite linear;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

/**
 * variants
 */

// primary
.primary {
  color: #fff;
  background: $color-primary;

  &:hover,
  &:focus {
    background: lighten($color-primary, 4%);
  }

  &:active {
    background: $color-primary;
  }
}

// primary outlined
.primaryOutlined {
  color: darken($color-primary, 8%);
  background: #fff;
  border: 2px solid $color-primary;

  &:hover,
  &:focus {
    color: #fff;
    background: $color-primary;
  }

  &:active {
    color: #fff;
    background: darken($color-primary, 8%);
    border-color: darken($color-primary, 8%);
  }
}

.secondary {
  color: white;
  background-color: #0167f5;
  border: none;

  &:hover,
  &:focus {
    background-color: lighten(#0167f5, 10%);
  }

  &:active {
    background-color: #0167f5;
  }
}

// outlined dark
.darkOutlined {
  color: $color-text;
  background: #fff;
  border: 2px solid $color-text;

  &:hover,
  &:focus {
    background: darken(#fff, 4%);
  }

  &:active {
    background: darken(#fff, 8%);
  }
}

// white shadow
.whiteShadow {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;

  &:hover,
  &:focus {
    background: #fff;
  }

  &:active {
    background: #fff;
  }
}

// Dark
.dark {
  color: #fff;
  background: #000;

  &:hover,
  &:focus {
    background: #222;
  }

  &:active {
    background: #000;
  }
}

.whiteOrange {
  color: $color-primary;
  background: #fff;

  &:hover,
  &:focus {
    background: #fff;
  }

  &:active {
    background: #e7e7e7;
  }
}

.linkToSettings {
  top: 200px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: #979797;
  cursor: pointer;
  background: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.02),
    0 1px 12px rgba(0, 0, 0, 0.12);

  transition: all 0.2s;

  &:hover {
    color: darken(#979797, 5%);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.07),
      0 1px 12px rgba(0, 0, 0, 0.18);
  }

  &:active {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.02),
      0 1px 12px rgba(0, 0, 0, 0.12);
  }
}

.iconButton {
  padding-right: 20px;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
