@use 'mixins' as *;

.creatorSectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #b7b7b7;

  > h2 {
    font-size: 27px;
    font-weight: 500;
    color: #000000;
    letter-spacing: 0.15px;
  }

  > a {
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
    letter-spacing: 0.15px;
    transition: all 0.1s;

    &:hover {
      color: rgba(0, 0, 0, 0.9);
    }
  }
}

.creatorList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 33px;
  padding: 0;
  margin: 20px 0 0;
  list-style: none;

  @include mq('lg') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq('sm') {
    grid-template-columns: repeat(1, 1fr);
  }
}

.creatorCard {
  overflow: hidden;
  text-decoration: none;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.01), 0 4px 8px rgba(0, 0, 0, 0.02),
    0 1px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.4s;

  &:hover {
    transform: translateY(-3px);
  }

  > img {
    width: 100%;
    height: auto;
    pointer-events: none;
    user-select: none;
  }

  > div {
    margin-top: -15px;
    text-align: center;

    > div {
      font-weight: 500;
      color: black;
    }

    > p {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 155px;
      padding: 5px 20px 20px;
      overflow: scroll;
      font-size: 12px;
      font-weight: normal;
      line-height: 18px;
      color: #707a83;
      text-align: center;
      letter-spacing: 0.15px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
