@use 'variables' as *;

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  box-shadow: 0px 2px 14px -9px rgba(0, 0, 0, 0.6);
}

.saleInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #bbb8b8;
  padding: 0;
}

.price {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.priceLabel {
  text-align: center;

  font-size: 12px;
  color: $color-primary;
}

.priceBody {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  > svg {
    height: 16px;
    fill: $color-primary;
  }

  > p {
    color: $color-primary;
    font-weight: bold;
    font-size: 16px;
  }
}

.amount {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid #bbb8b8;
}

.amountLabel {
  text-align: center;
  font-size: 12px;
  color: gray;
}

.amountBody {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #000;
}
